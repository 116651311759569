import { validatePIS, validateSequencial } from "../components/utils/ConsumerInfo";
import FormHelper from "../components/utils/FormHelper";

/* configuração para formulário do usuário */
const Userform = (props, startValues) => {
  let { editProfile, data = {}, isFarmer, profiles, isfakeDomain, dataHelper } = props;
  let usernameType = startValues?.Username ? FormHelper.detectStringContent(startValues.Username) : 'email';
  let isAgricultor = !data.user?.acg_ProfileCodes__c || data.user?.acg_ProfileCodes__c?.includes('agricultor');
  const category = data?.user?.LoyaltyCategory__c;

  switch (usernameType) {
    case 'cpf': case 'cnpj':
      startValues.CNPJ_CPF__c = startValues.Username;
      break;
    case 'email':
      startValues.Email = startValues.Username;
      break;
    default:
  }

  // let isEmailDisabled = startValues?.Email || (editProfile && data?.user?.Email && (!data?.user?.AuthRegisterEmail || data?.user?.EmailIsReal));
  let isEmailDisabled = !editProfile && startValues?.Email;

  let form = {
    Content1: FormHelper.useFormInput({
      type: 'content',
      content: 'Os itens com * são obrigatórios',
    }),
    LoyaltyCategory__c: FormHelper.useFormInput({
      id: 'userCategory',
      label: props.config.strings.form.label.USER_CATEGORY,
      type: editProfile ? 'text' : 'select',
      options: [
        {
          value: 'Agricultor',
          label: 'Agricultor(a) ou Familiar',
        },
        {
          value: 'Colaborador da fazenda',
          label: 'Colaborador(a) da fazenda',
        },
        {
          value: 'Demais profissionais agrícolas',
          label: 'Demais profissionais agrícolas',
        },
        {
          value: 'Estudante',
          label: 'Estudante',
        },
        {
          value: 'Pesquisador / Influenciador',
          label: 'Pesquisador(a) / Influenciador(a)',
        },
      ],
      validations: editProfile ? null : [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_USER_CATEGORY,
      }],
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : '',
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
      },
      startValue: startValues?.LoyaltyCategory__c,
    }),
    Username: FormHelper.useFormInput({
      id: 'username',
      label: props.config.strings.form.label.USERNAME_TEXT,
      type: usernameType === 'text' ? 'text' : 'hidden',
      inputOptions: {
        disabled: 'disabled',
        spellCheck: false,
      },
      startValue: startValues?.Username,
    }),
    CNPJ_CPF__c: FormHelper.useFormInput({
      id: 'cpf',
      label: props.config.strings.form.label.CPF,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CPFCNPJ,
        },
        {
          type: 'cpf',
          errorMessage: props.config.strings.form.validation.INVALID_CPF,
        },
      ],
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile || usernameType === 'cpf' || usernameType === 'cnpj' ? 'disabled' : false,
        inputMode: 'numeric',
        spellCheck: false,
      },
      mask: 'username',
      maskOptions: ['cpf', 'cnpj'],
      startValue: startValues?.CNPJ_CPF__c,
    }),
    CompanyName: FormHelper.useFormInput({
      id: 'companyName',
      label: props.config.strings.form.label.COMPANY_NAME,
      type: usernameType === 'cnpj' ? 'text' : 'hidden',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_COMPANY_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_COMPANY_NAME,
        }
      ],
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
        maxLength: 40,
      },
      startValue: startValues?.FirstName,
    }),
    FirstName: FormHelper.useFormInput({
      id: 'firstName',
      label: props.config.strings.form.label.FIRST_NAME,
      type: usernameType === 'cnpj' ? 'hidden' : 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_FIRST_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_FIRST_NAME,
        }
      ],
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
        maxLength: 40,
      },
      startValue: startValues?.FirstName,
    }),
    LastName: FormHelper.useFormInput({
      id: 'lastName',
      label: props.config.strings.form.label.LAST_NAME,
      type: usernameType === 'cnpj' ? 'hidden' : 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_LAST_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_LAST_NAME,
        },
      ],
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
        maxLength: 60,
      },
      startValue: startValues?.LastName,
    }),
    LoyaltyPhone__c: FormHelper.useFormInput({
      id: 'phone',
      label: props.config.strings.form.label.PHONE,
      type: 'text',
      inputOptions: {
        // title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        inputMode: 'numeric',
        spellCheck: false,
      },
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PHONE,
        },
        {
          type: 'phone',
          errorMessage: props.config.strings.form.validation.INVALID_PHONE,
        },
      ],
      mask: 'phone',
      startValue: startValues?.LoyaltyPhone__c,
    }),
    acg_Rg__c: FormHelper.useFormInput({
      id: 'rg',
      label: props.config.strings.form.label.RG,
      type: 'text',
      hideOn: !isFarmer && editProfile ? null : ['create', 'update'],
      validations: !profiles?.includes('Consultor') || profiles?.includes('Focal Point') ? [
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.ALL_EQUAL_RG,
          customFunction: (formInput) => {
            const value = formInput?.getValue().toLowerCase();
            const primeiroCaractere = value[0];

            const all = value?.length;
            let count = 1;

            for (let i = 1; i < value.length; i++) {
              if (value[i] === primeiroCaractere) {
                count += 1;
              }
            }

            if (count === all) {
              return false;
            } else {
              return true;
            }
          }
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.SEQUENCIAL,
          customFunction: (formInput) => {
            const value = formInput?.getValue().toLowerCase();
            return validateSequencial(value);
          }
        }
      ] : [],
      inputOptions: {
        inputMode: 'numeric',
        spellCheck: false,
        maxLength: 13,
        disabled: false,
      },
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      mask: 'rg',
      startValue: startValues?.acg_Rg__c,
    }),
    acg_Pis__c: FormHelper.useFormInput({
      id: 'pis',
      label: props.config.strings.form.label.PIS,
      type: 'text',
      hideOn: !isFarmer && editProfile ? null : ['create', 'update'],
      validations: !profiles?.includes('Consultor') || profiles?.includes('Focal Point') ? [
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.ALL_EQUAL_PIS,
          customFunction: (formInput) => {
            const value = formInput?.getValue().toLowerCase();
            const primeiroCaractere = value[0];

            const all = value?.length;
            let count = 1;

            for (let i = 1; i < value.length; i++) {
              if (value[i] === primeiroCaractere) {
                count += 1;
              }
            }

            if (count === all) {
              return false;
            } else {
              return true;
            }
          }
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.INVALID_PIS,
          customFunction: (formInput) => {
            const value = formInput?.getValue().toLowerCase();
            
            if(value) {
              return validatePIS(value);
            } else {
              return true;
            }
          }
        }
      ] : [],
      inputOptions: {
        inputMode: 'numeric',
        spellCheck: false,
        disabled: false,
        maxLength: 11
      },
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      mask: 'number',
      startValue: startValues?.acg_Pis__c,
    }),
    Email: FormHelper.useFormInput({
      id: 'email',
      label: isAgricultor
        ? props.config.strings.form.label.EMAIL
        : props.config.strings.form.label.EMAIL_REQUIRED,
      type: 'text',
      mask: 'email',
      validations:
        isAgricultor
          ?
          [
            {
              type: 'email',
              errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
            }
          ]
          :
          !isfakeDomain ? [
            {
              type: 'required',
              errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
            },
            {
              type: 'email',
              errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
            }
          ] : [],
      inputOptions: {
        // title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        // disabled: isEmailDisabled,
        spellCheck: false,
        disabled: editProfile || isEmailDisabled ? 'disabled' : false,
      },
      startValue: startValues?.Email,

    }),
    LoyaltyBirthdate__c: FormHelper.useFormInput({
      id: 'birthdate',
      label: props.config.strings.form.label.BIRTH_DATE,
      type: 'text',
      mask: 'date',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_BIRTH_DATE,
        },
        {
          type: 'age',
          config: { minAge: 18, maxAge: 130 },
          errorMessage: props.config.strings.form.validation.INVALID_DATE,
        },
      ],
      inputOptions: {
        spellCheck: false,
        inputMode: 'numeric',
        disabled: false
      },
      startValue: startValues?.LoyaltyBirthdate__c,
    }),
    LoyaltyPostalCode__c: FormHelper.useFormInput({
      id: 'postalCode',
      hideOn: ['create'],
      label: props.config.strings.form.label.CEP,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CEP,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.INVALID_ZIPCODE,
          customFunction: async (formInput, validation, aInputs) => {
            let cep = formInput.getValue();
            let result = await FormHelper.getAddressByCEP(cep, dataHelper);

            return cep.length === 0 || result.isValid;
          }
        },
      ],
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      size: {
        desktopQty: 3,
        tabletQty: 12,
      },
      mask: 'cep',
      startValue: startValues?.LoyaltyPostalCode__c,
    }),
    LoyaltyStreet__c: FormHelper.useFormInput({
      id: 'street',
      hideOn: ['create'],
      label: props.config.strings.form.label.STREET,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_STREET,
        }
      ],
      mask: 'alphanumeric',
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      size: {
        desktopQty: 9,
        tabletQty: 12,
      },
      startValue: startValues?.LoyaltyStreet__c,
    }),
    acg_AddressNumber__c: FormHelper.useFormInput({
      id: 'addressNumber',
      hideOn: ['create'],
      label: props.config.strings.form.label.ADDRESSNUMBER,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_NUMBER,
        }
      ],
      mask: 'number',
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      size: {
        desktopQty: 6,
        tabletQty: 6,
      },
      startValue: startValues?.acg_AddressNumber__c,
    }),
    acg_AddressComplement__c: FormHelper.useFormInput({
      id: 'addressComplement',
      hideOn: ['create'],
      label: props.config.strings.form.label.COMPLEMENT,
      type: 'text',
      mask: 'alphanumeric',
      inputOptions: {
        spellCheck: false,
        maxLength: 20,
        disabled: false
      },
      size: {
        desktopQty: 6,
        tabletQty: 6,
      },
      startValue: startValues?.acg_AddressComplement__c,
    }),
    acg_AddressDistrict__c: FormHelper.useFormInput({
      id: 'district',
      hideOn: ['create'],
      label: props.config.strings.form.label.DISTRICT,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_DISTRICT,
        }
      ],
      mask: 'alphanumeric',
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      startValue: startValues?.acg_AddressDistrict__c,
    }),
    LoyaltyState__c: FormHelper.useFormInput({
      id: 'state',
      label: props.config.strings.form.label.STATE,
      type: 'select',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_STATE,
      }],
      options: [
        { value: 'AC', label: 'Acre' },
        { value: 'AL', label: 'Alagoas' },
        { value: 'AP', label: 'Amapá' },
        { value: 'AM', label: 'Amazonas' },
        { value: 'BA', label: 'Bahia' },
        { value: 'CE', label: 'Ceará' },
        { value: 'DF', label: 'Distrito Federal' },
        { value: 'ES', label: 'Espírito Santo' },
        { value: 'GO', label: 'Goías' },
        { value: 'MA', label: 'Maranhão' },
        { value: 'MT', label: 'Mato Grosso' },
        { value: 'MS', label: 'Mato Grosso do Sul' },
        { value: 'MG', label: 'Minas Gerais' },
        { value: 'PA', label: 'Pará' },
        { value: 'PB', label: 'Paraíba' },
        { value: 'PR', label: 'Paraná' },
        { value: 'PE', label: 'Pernambuco' },
        { value: 'PI', label: 'Piauí' },
        { value: 'RJ', label: 'Rio de Janeiro' },
        { value: 'RN', label: 'Rio Grande do Norte' },
        { value: 'RS', label: 'Rio Grande do Sul' },
        { value: 'RO', label: 'Rondônia' },
        { value: 'RR', label: 'Roraíma' },
        { value: 'SC', label: 'Santa Catarina' },
        { value: 'SP', label: 'São Paulo' },
        { value: 'SE', label: 'Sergipe' },
        { value: 'TO', label: 'Tocantins' },
      ],
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      startValue: startValues?.LoyaltyState__c,
    }),
    LoyaltyCity__c: FormHelper.useFormInput({
      id: 'city',
      label: props.config.strings.form.label.CITY,
      type: 'select',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_CITY,
      }],
      inputOptions: {
        spellCheck: false,
        disabled: false
      },
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      startValue: startValues?.LoyaltyCity__c,
    }),
    Password: FormHelper.useFormInput({
      id: 'password',
      hideOn: ['update'],
      label: props.config.strings.form.label.PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PASSWORD,
        },
        {
          type: 'passwordTips',
          errorMessage: props.config.strings.form.validation.INVALID_PASSWORD_COMPOSITION,
        }
      ],
      contentTips: ['chars:8', 'uppercase:1', 'lowercase:1', 'number:1', 'symbol:1'],
    }),
    PasswordConfirmation: FormHelper.useFormInput({
      id: 'password-confirmation',
      hideOn: ['update'],
      label: props.config.strings.form.label.CONFIRM_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CONFIRM_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_PASSWORD,
          customFunction: async (formInput, validation, aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if (input.id === 'password' && input.getValue() !== formInput.getValue()) {
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
      ],
    }),
    // TermsConfirmation: FormHelper.useFormInput({
    //   id: 'terms-confirmation',
    //   hideOn: ['update'],
    //   label: props.config.strings.form.label.TERMS_AND_PRIVACY,
    //   type: 'checkbox',
    //   validations: [{
    //     type: 'required',
    //     errorMessage: props.config.strings.form.validation.EMPTY_TERMS_CHECKBOX,
    //   }],
    // }),
    acg_ReferralCodeUsed__c: FormHelper.useFormInput({
      id: 'acg_ReferralCodeUsed__c',
      hideOn: ['update'],
      label: props.config.strings.form.label.REFERRAL_CODE_USED,
      type: 'text',
      mask: 'alphanumeric',
      inputOptions: {
        spellCheck: false,
      },
      size: {
        desktopQty: 6,
        tabletQty: 9,
      },
      startValue: startValues?.acg_ReferralCodeUsed__c
    }),
  }

  form.LoyaltyPostalCode__c.onChange = async (value) => {
    form.LoyaltyPostalCode__c.setError('')
    let result = await FormHelper.getAddressByCEP(value, dataHelper);

    if (result.isValid) {
      // let streetInfo = [result.address.street,result.address.neighborhood];
      // streetInfo = streetInfo.filter(s => s).join(', ');
      form.LoyaltyStreet__c.setValue(result.address.street);
      form.acg_AddressDistrict__c.setValue(result.address.neighborhood);
      form.LoyaltyState__c.setValue(result.address.uf);

      setTimeout(() => {
        form.LoyaltyCity__c.setValue(result.address.city);
      }, 500)
    }
    else if (result.isComplete) {
      // está completo e inválido
      form.LoyaltyPostalCode__c.setError('CEP inválido!')
    }
  }

  form.CNPJ_CPF__c.onChange = (value) => {
    if (FormHelper.detectStringContent(value) === 'cnpj') {
      form.LoyaltyBirthdate__c.setType('hidden')
    }
    else {
      form.LoyaltyBirthdate__c.setType('text')
    }
  }

  return form;
}

export default Userform;