export const infoCard = [
  {
    label: 'Até 30 dias',
    value: 30,
    stateName: 'ateTres'
  },
  {
    label: 'Até 60 dias',
    value: 60,
    stateName: 'ateSeis'
  },
  {
    label: 'Até 90 dias',
    value: 90,
    stateName: 'ateNove'
  }
];

export const infoOrder = [
  {
    label: 'Data da ação',
    value: 'PointDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Data da expiração',
    value: 'ExpirationDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Ação',
    value: 'Action__c',
    defaultOrder: 'asc',
  },
  {
    label: 'Descrição',
    value: 'Description__c',
    defaultOrder: 'asc',
  },

  {
    label: 'Ganho comercial',
    value: 'Points__c',
    defaultOrder: 'desc',
  }
];

export const traducoes = {
  Reversal: 'Estornos',
  Canceled: 'Cancelados',
  Loan: 'Empréstimos',
  Bonus: 'Bônus',
  Earned: 'Pontuações',
  Redeem: 'Resgates',
  Expired: 'Expirações',
  Manual: 'Fornecidos',
  Debt: 'Débitos',
  Reserved: 'Reservados',
  Transfered: 'Transferência',
};

export const formatCPF = (str) => (
  str
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);

export const formatCNPJ = str => (
  str
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);


export const infoOrderSurvay = [
  {
    label: 'Título',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Divisão',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Data',
    value: '',
    defaultOrder: 'asc',
  },
  {
    label: 'Questões',
    value: '',
    defaultOrder: 'asc',
  },

  {
    label: 'Pontuação',
    value: '',
    defaultOrder: 'desc',
  }
];

export const validateSequencial = (value) => {
  const rg = value;

  let sequentials = 0;

  for (let i = 1; i < rg.length; i += 1) {
    if (Number(rg[i]) === Number(rg[0]) + i) {
      sequentials += 1;
    }
  }

  if (sequentials === rg.length - 1) {
    return false;
  }

  return true
};

export const validatePIS = (pis) => {
  pis = pis.replace(/\D/g, '');
  let digito = pis[pis.length - 1];

  if (pis.length !== 11) {
    return false;
  }

  const pesos = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(pis.charAt(i)) * pesos[i];
  }

  let resto = soma % 11;

  let resultado = 11 - resto;

  if (parseFloat(digito) === 0 && (parseFloat(resultado) === 10 || parseFloat(resultado) === 11)) {
    return true;
  } else if (parseFloat(resultado) === parseFloat(digito)) {
    return true
  } else {
    return false
  };
}

export const getWorksheetFormatedData = (data = []) => {
  const removeFields = (row) => {
    if (row.Percentual_do_Consultor === 0) {
      delete row.Valor_Base_Premiacao_Consultor;
      delete row.Valor_Campanhas_Premiacao_Consultor;
      delete row.Valor_Total_Premiacao_Consultor_Base_mais_Campanhas;
      delete row.Descricao_de_Campanha_Geral_Consultor;
      delete row.Descricao_de_Campanha_Consultor;
      delete row.Percentual_do_Consultor;
    }

    if (row.Percentual_Gerente === 0) {
      delete row.Valor_Base_Premiacao_Gerente;
      delete row.Valor_Campanhas_Premiacao_Gerente;
      delete row.Valor_Total_Premiacao_Gerente_Base_mais_Campanhas;
      delete row.Descricao_Campanha_Geral_Gerente;
      delete row.Descricao_Campanha_Gerente;
      delete row.Nome_Gerente;
      delete row.CPF_Gerente;
      delete row.Percentual_Gerente;
    }

    if (row.Percentual_Gerente_Filial === 0) {
      delete row.Valor_Base_Premiacao_Gerente_Filial;
      delete row.Valor_Campanhas_Premiacao_Gerente_Filial;
      delete row.Valor_Total_Premiacao_Gerente_Filial_Base_mais_Campanhas;
      delete row.Descricao_Campanha_Geral_Gerente_Filial;
      delete row.Descricao_Campanha_Gerente_Filial;
      delete row.Nome_Gerente_Filial;
      delete row.CPF_Gerente_Filial;
      delete row.Percentual_Gerente_Filial;
    }

    if (row.Percentual_Vendedor_de_Rota === 0) {
      delete row.Percentual_Vendedor_de_Rota;
      delete row.Valor_Base_Premiacao_do_Vendedor_de_Rota;
      delete row.Valor_Campanhas_Premiacao_Vendedor_de_Rota;
      delete row.Valor_Total_Premiacao_Vendedor_de_Rota_Base_mais_Campanhas;
      delete row.Descricao_Campanha_Geral_Vendedor_de_Rota;
      delete row.Descricao_Campanha_Vendedor_de_Rota;
      delete row.Nome_Vendedor_de_Rota;
      delete row.CPF_Vendedor_de_Rota;
      delete row.Percentual_Vendedor_de_Rota;
    }

    if (row.Percentual_Gerador_de_Demanda === 0) {
      delete row.Percentual_Gerador_de_Demanda;
      delete row.Valor_Base_Premiacao_Gerador_de_Demanda;
      delete row.Valor_Campanhas_Premiacao_Gerador_de_Demanda;
      delete row.Valor_Total_Premiacao_Gerador_Demanda_Base_mais_Campanhas;
      delete row.Descricao_Campanha_Geral_Gerador_de_Demanda;
      delete row.Descricao_Campanha_Gerador_de_Demanda;
      delete row.CPF_Gerador_de_Demanda;
      delete row.Nome_Gerador_de_Demanda;
      delete row.Percentual_Gerador_de_Demanda;
    }

    if (row.Percentual_Telemarketing === 0) {
      delete row.Percentual_Telemarketing;
      delete row.Valor_Base_Premiação_Telemarketing;
      delete row.Valor_Campanhas_Premiacao_Telemarketing;
      delete row.Valor_Total_Premiacao_Telemarketing_Base_mais_Campanhas;
      delete row.Descricao_Campanha_Geral_Telemarketing;
      delete row.Descricao_Campanha_Telemarketing;
      delete row.CPF_Telemarketing;
      delete row.Nome_Telemarketing;
      delete row.Percentual_Telemarketing;
    }
  };

  const wsData = data.map((row) => {
    removeFields(row);

    return row
  });

  return wsData;
};

export const generateRandomNumber = () => {
  try {
    const crypto = window.crypto || window.msCrypto;
    let array = new Uint32Array(1);
    const numberRandom = crypto.getRandomValues(array);
    return numberRandom;
  } catch (error) {
    return 0;    
  }
};

