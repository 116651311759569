import { useEffect, useState } from "react";
import FormHelper from "../components/utils/FormHelper";
import moment from "moment";
import styled from "@emotion/styled";

/* configuração para formulário do fale conosco */

const Caseform = (props,startValues) => {
  let [ types, setTypes ] = useState([]);

  let reasonInput = FormHelper.useFormInput({
    id: 'reason',
    label: props.config.strings.form.label.REASON,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_REASON,
    }],
    startValue: startValues?.Reason,
  })

  let typeInput = FormHelper.useFormInput({
    id: 'type',
    label: props.config.strings.form.label.TYPE,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_TYPE,
    }],
    // startValue: startValues?.Type,
    onChange: (value) => {
      let type = types.filter(type => {
        return type.value === value
      })

      if(type[0]?.reasons){
        reasonInput.setOptions(type[0].reasons);
      }
    }
  })

  useEffect(() => {
    props.dataHelper.getCaseTypes((caseTypes) => {
      let userDeslogado = Object.keys(props.data.user).length === 0;

      if(userDeslogado) {
        const caseTypeList = caseTypes.filter((data) => data.label != 'Nota Fiscal');
        typeInput.setOptions(caseTypeList);
        setTypes(caseTypeList);
        typeInput.setValue(startValues?.Type);
        return
      }

      typeInput.setOptions(caseTypes);
      setTypes(caseTypes);
      typeInput.setValue(startValues?.Type);
    });
  },[])

  let form = {
    Content1: FormHelper.useFormInput({
      type: 'content',
      content: 'Os itens com * são obrigatórios',
    }),
    Name: FormHelper.useFormInput({
      id: 'name',
      label: props.config.strings.form.label.NAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_FIRST_NAME,
        }
      ],
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.Name,
    }),
    Email: FormHelper.useFormInput({
      id: 'email',
      label: props.config.strings.form.label.EMAIL_REQUIRED,
      type: 'text',
      mask: 'email',
      // validations: [{
      //   type: 'required',
      //   errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
      // }],
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
        },
        {
          type: 'email',
          errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
        }
      ],
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.Email,
    }),
    LoyaltyPhone__c: FormHelper.useFormInput({
      id: 'phone',
      label: props.config.strings.form.label.PHONE,
      type: 'text',
      inputOptions: {
        inputMode: 'numeric',
        spellCheck: false,
      },
      // validations: [{
      //   type: 'required',
      //   errorMessage: props.config.strings.form.validation.EMPTY_PHONE,
      // }],
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PHONE,
        },
        {
          type: 'phone',
          errorMessage: props.config.strings.form.validation.INVALID_PHONE,
        },
      ],
      mask: 'phone',
      startValue: startValues?.LoyaltyPhone__c,
    }),
    Type: typeInput,
    Reason: reasonInput,
    Description: FormHelper.useFormInput({
      id: 'description',
      label: props.config.strings.form.label.DESCRIPTION,
      type: 'textarea',
      validations: [{
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_DESCRIPTION,
      }],
      inputOptions: {
        spellCheck: false,
        maxLength: 250,
      },
      startValue: startValues?.Description,
    }),
    Content3: FormHelper.useFormInput({
      type: 'hidden',
      content: <MessageImageBox>
        <span>Para casos de alteração de e-mail e/ou telefone, por favor, informe o e-mail e/ou telefone correto para entrarmos em contato.</span>
      </MessageImageBox>,
    }),
    Content2: FormHelper.useFormInput({
      type: 'hidden',
      content: <MessageImageBox>
        <span>Documentos aceitos para a validação: Registro Geral (RG) e a Carteira Nacional de Habilitação (CNH) no formato "PNG, JPG ou JPEG".</span>
      </MessageImageBox>,
    }),
    DocumentPhoto1: FormHelper.useFormInput({
      label: 'Selfie + Frente do documento (Lado com foto)',
      type: 'hidden',
      layout: 'holder',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_PHOTO,
      }],
      inputOptions: {
        path: 'CaseFiles',
        imageIdGenerator: () => {
          return "imagem_case-" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11);
        },
        accept: ".jpg, .jpeg, .png"
      },
      size: {
        desktopQty: 6,
        tabletQty: 6,
      },
    }),
    DocumentPhoto2: FormHelper.useFormInput({
      label: 'CNH (Frente) ou RG (Frente e verso)',
      type: 'hidden',
      layout: 'holder',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_PHOTO,
      }],
      inputOptions: {
        path: 'CaseFiles',
        imageIdGenerator: () => {
          return "imagem_case-" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11);
        },
        accept: ".jpg, .jpeg, .png"
      },
      size: {
        desktopQty: 6,
        tabletQty: 6,
      },
    }),
    Images__c: FormHelper.useFormInput({
      id: 'images',
      label: 'Anexar imagens (PNG, JPG ou JPEG)',
      type: 'image',
      inputOptions: {
        path: 'CaseFiles',
        imageIdGenerator: () => {
          return "imagem_case-" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11);
        },
        maxImages: 4,
        accept: ".jpg, .jpeg, .png"
      }
    })
  }

  form.Reason.onChange = (value) => {
    let reasonsToAskUserPhoto = props.userHelper.getCaseReasonsToAskUserDocument();

    if(reasonsToAskUserPhoto.includes(value)){
      form.DocumentPhoto1.setType('image');
      form.DocumentPhoto2.setType('image');
      form.Content3.setType('content');
      form.Content2.setType('content');
      form.Images__c.setType('hidden');
    }
    else{
      form.DocumentPhoto1.setType('hidden');
      form.DocumentPhoto2.setType('hidden');
      form.Content3.setType('hidden');
      form.Content2.setType('hidden');
      form.Images__c.setType('image');
    }
  }

  return form;
}

export default Caseform;

const MessageImageBox = styled.div`
  background-color: #F6F6F6;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  span {
    color: #4d80af;
    font-weight: 700;
  }
`